import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const borderRadiusFromCorners = (corners) =>
  corners === "round" ? "5px" : "none";

const makeCustomProperties = (keyValues) =>
  Object.entries(keyValues)
    .map(([key, value]) => `--${key}: ${value};`)
    .join("");

const themeToCSSRules = ({ primaryColor, accentColor, corners }) =>
  makeCustomProperties({
    unividPrimaryColor: primaryColor,
    unividAccentColor: accentColor,
    unividCornerBorderRadius: borderRadiusFromCorners(corners),
    deleteColor: "#d11a2a",
    darkBlue: "#1e5fa6",
  });

const ThemeStyle = () => {
  const theme = useSelector((state) => state.theme);

  const cssRules = themeToCSSRules(theme);

  return (
    <Helmet>
      <style>{`:root {${cssRules}}`}</style>
    </Helmet>
  );
};

ThemeStyle.propTypes = {
  theme: PropTypes.exact({
    hasFetchedTheme: PropTypes.bool,
    backgroundImage: PropTypes.string,
    uploadedBackgroundImage: PropTypes.string,
    useBackgroundBlur: PropTypes.bool,
    primaryColor: PropTypes.string.isRequired,
    accentColor: PropTypes.string.isRequired,
    headerFade: PropTypes.exact({
      startColor: PropTypes.string,
      endColor: PropTypes.string,
    }),
    corners: PropTypes.oneOf(["round", "sharp"]).isRequired,
    headerLogo: PropTypes.string,
    splashImage: PropTypes.string,
    uploadedSplashImage: PropTypes.string,
    useSplashImageInConference: PropTypes.bool,
  }),
};

export default ThemeStyle;
