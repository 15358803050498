import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import { useEffect } from "react";

const environment = process.env.REACT_APP_IS_STAGING
  ? "staging"
  : process.env.NODE_ENV;

Sentry.init({
  dsn: "https://630619cef1a04e35b65c76d6ee60acd2@sentry.unividpoint.com/4",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        "localhost",
        "api.univid.io",
        /^https:\/\/api\.univid\.io/,
      ],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      sessionSampleRate: 1.0,
      errorSampleRate: 1.0,
    }),
  ],

  environment,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  normalizeDepth: 10,
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    "api.univid.io",
    /^https:\/\/api\.univid\.io/,
    /^\//,
  ],
});
