import { textGenerator } from "../text/login_regular.js";
import styles from "./ProceedToConsent.module.css";

const ProceedToConsent = (props) => {
  return (
    <span className={styles.ConsentTextContainer}>
      {textGenerator("iConsentToUnivids", props.language)}
      &nbsp;
      <a
        className={styles.ConsentLink}
        href="https://univid.io/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        {textGenerator("termsOfUse", props.language)}
      </a>
      &nbsp;{textGenerator("and", props.language)}&nbsp;
      <a
        className={styles.ConsentLink}
        href="https://univid.io/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        {textGenerator("privacyAgreement", props.language)}
      </a>
    </span>
  );
};

export default ProceedToConsent;
