import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";

const isProduction = process.env.NODE_ENV === "production";

const trackEvent = (eventData) => {
  if (eventData?.event == null) {
    return;
  }

  const eventName = eventData.event;

  try {
    mixpanel.track(eventName, eventData);
  } catch (error) {
    console.log("Could not log to mixpanel");
  }

  if (!isProduction) {
    return;
  }

  try {
    if (eventData.eventProps) {
      ReactGA.event(eventName, eventData.eventProps);
    }
  } catch (error) {
    console.log("Could not log to Google Analytics");
  }
};

export default trackEvent;
