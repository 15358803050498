import mixpanel from "mixpanel-browser";

const configuration = {
  production: {
    key: "8881a52a493923b8bb501a3df8ff5213",
    options: {
      host: "api-eu.mixpanel.com",
      opt_out_tracking_by_default: true,
    },
  },
  default: {
    key: "aeb38c5fa4250cad295708cdaa589bb1",
    options: {
      debug: true,
      host: "api-eu.mixpanel.com",
      ignore_dnt: true,
    },
  },
};

const setupMixPanel = () => {
  const envSpecificConfig =
    configuration[process.env.NODE_ENV] ?? configuration.default;

  mixpanel.init(envSpecificConfig.key, envSpecificConfig.options);
};

export default setupMixPanel;
