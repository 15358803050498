import { useEffect, Suspense } from "react";
import { useSearchParams } from "react-router-dom";

import { Outlet, useNavigate } from "react-router";

import useBetterLocation from "./components/utils/useBetterLocation.js";
import betterGetDimensionsHook from "./components/utils/betterGetDimensionsHook.js";

import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

import { useDispatch, useSelector } from "react-redux";

import {
  getUserFetch,
  tryUnauthLoginWithToken,
} from "./redux/actions/userActions";
import { initializeBrowserInfo } from "./redux/slices/browserSlice";

import "./components/css/App.css";
import "./components/css/Text.css";

import styles from "./components/css/App.module.css";

import ErrorBoundary from "./components/errorboundary/ErrorBoundary.js";
import ThemeStyle from "./components/themestyle/ThemeStyle.js";
import { setUserTimeZone } from "./utils/userTimeZone.js";

import { parseUser } from "./utils/parseUser.js";

const isMobile = require("is-mobile");
const isMobileUnit = isMobile();

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.user.currentUser);
  const hasFetchedUser = useSelector((state) => state.user.hasFetchedUser);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const isAuthenticatedUnauth = useSelector(
    (state) => state.user.isAuthenticatedUnauth,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  // We only need this on phone...
  const dimensionsObject = isMobileUnit ? betterGetDimensionsHook() : null;

  const { pathname, search } = useBetterLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const authToken = queryParams.get("authToken");

    if (typeof authToken === "string" && authToken?.length > 0) {
      // We have an auth token
      searchParams.delete("authToken");
      setSearchParams(searchParams);

      dispatch(tryUnauthLoginWithToken(authToken));
    } else {
      // No token
      if (!isAuthenticated && !isAuthenticatedUnauth) {
        dispatch(getUserFetch());
      }
    }
  }, [isAuthenticated, isAuthenticatedUnauth, search, searchParams]);

  useEffect(() => {
    const { userName, userEmail, userId } = parseUser(currentUser);

    if (isAuthenticated || isAuthenticatedUnauth) {
      const mixPanelDataToSet = {
        ...currentUser,
        $first_name: currentUser.first_name,
        $last_name: currentUser.last_name,
      };

      const sentryDataToSet = {
        id: currentUser._id,
        ip_address: "{{auto}}",
      };

      if (userName) {
        mixPanelDataToSet.$name = userName;
        sentryDataToSet.username = userName;
      }

      if (userEmail) {
        mixPanelDataToSet.$email = userEmail;
        sentryDataToSet.email = userEmail;
      }

      try {
        mixpanel.identify(userId);
        mixpanel.people.set(mixPanelDataToSet);
      } catch (error) {
        console.log("Could not set Mixpanel data");
      }

      try {
        Sentry.setUser(sentryDataToSet);
      } catch (error) {
        console.log("Could not set Sentry data");
      }

      try {
        ReactGA.set({ userId });
      } catch (error) {
        console.log("Could not set Google Analytics data");
      }

      if (currentUser?.timeZone == null) {
        setUserTimeZone({ isRegularUser: currentUser.is_regular_user });
      }
    }
  }, [currentUser, isAuthenticated, isAuthenticatedUnauth]);

  useEffect(() => {
    // 1) BODY DETERMINS THE HEIGHT
    // 2) ROOT DISPLAYS IT

    let newWidthString = "1vw";
    let newHeightString = "1vh";

    // Will only apply if mobile
    if (dimensionsObject != null) {
      const newWidth = dimensionsObject.width;
      const newHeight = dimensionsObject.height;

      if (newWidth != null && newHeight != null) {
        newWidthString = newWidth * 0.01 + "px";
        newHeightString = newHeight * 0.01 + "px";
      }
    }

    if (document.getElementById("root") != null) {
      document.getElementById("root").style.setProperty("--vw", newWidthString);
      document
        .getElementById("root")
        .style.setProperty("--vh", newHeightString);
    } else {
      document.documentElement.style.setProperty("--vw", newWidthString);
      document.documentElement.style.setProperty("--vh", newHeightString);
    }
  }, [dimensionsObject]);

  useEffect(() => {
    if (pathname.length > 0 && pathname[pathname.length - 1] !== "/") {
      navigate(pathname + "/" + search, {
        replace: true,
      });

      return;
    }

    dispatch(initializeBrowserInfo());
  }, [pathname, search, navigate]);

  return (
    <ErrorBoundary>
      <ThemeStyle />
      <div className={styles.AppContainer}>
        <div className={styles.HeaderContainer} id="header-container" />
        <div className={styles.ContentContainer}>
          {hasFetchedUser ? (
            <Suspense fallback={<div className={styles.MainLoaderContainer} />}>
              <Outlet />
            </Suspense>
          ) : (
            <div />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
