import "./tracking/setupSentry.js";

import "react-app-polyfill/stable";

import { createRoot } from "react-dom/client";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { IntercomProvider } from "react-use-intercom";

import App from "./App.js";
import store from "./redux/store.js";

import CookieConsent from "./tracking/CookieConsent.js";
import IntercomWrapper from "./tracking/intercom/IntercomWrapper.js";

import LogInPage from "./components/login/LogInPage";
import { VideoPlayerEmbedRedirect } from "./components/videocontainer/videoplayers/VideoPlayerEmbedRedirect.js";

// "jtnc9kzz" is the test workspace app id
const INTERCOM_APP_ID = "q634mzts";

window.HELP_IMPROVE_VIDEOJS = false; // Disable video.js analytics

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const getLazyComponent = async (importPromise) => {
  const ComponentIndex = await importPromise;
  return { Component: ComponentIndex.default };
};

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route element={<CookieConsent />}>
        <Route element={<IntercomWrapper />}>
          <Route
            index
            lazy={() => getLazyComponent(import("./RootRedirect.js"))}
          />

          <Route
            exact
            path="/webinars/template-create"
            lazy={() =>
              getLazyComponent(
                import(
                  "./components/createsession/CreateTemplateWebinarWrapper.js"
                ),
              )
            }
          />

          <Route
            exact
            path="/createSession"
            lazy={() =>
              getLazyComponent(
                import("./components/createsession/CreateSession"),
              )
            }
          />
          <Route
            exact
            path="/create-webinar"
            lazy={() =>
              getLazyComponent(
                import("./components/createsession/CreateSession"),
              )
            }
          />
          <Route
            path="/session/:id"
            lazy={() =>
              getLazyComponent(import("./components/session/SessionWrapper"))
            }
          >
            <Route
              index
              lazy={() =>
                getLazyComponent(
                  import("./components/session/SessionContainer"),
                )
              }
            />

            <Route
              path="register"
              lazy={() =>
                getLazyComponent(
                  import("./components/session/registration/RegistrationPage"),
                )
              }
            />

            <Route
              path="embed"
              lazy={() =>
                getLazyComponent(
                  import(
                    "./components/videocontainer/videoplayers/VideoPlayerEmbed"
                  ),
                )
              }
            />

            <Route
              path="login"
              lazy={() =>
                getLazyComponent(
                  import("./components/session/login/SessionLoginWrapper"),
                )
              }
            >
              {/* TODO: Replace this default with "common" login */}
              <Route
                index
                lazy={() =>
                  getLazyComponent(
                    import("./components/session/login/LoginUnividPage"),
                  )
                }
              />
              <Route
                path="univid"
                lazy={() =>
                  getLazyComponent(
                    import("./components/session/login/LoginUnividPage"),
                  )
                }
              />
              <Route
                path="google"
                lazy={() =>
                  getLazyComponent(
                    import("./components/session/login/LoginGooglePage"),
                  )
                }
              />
              <Route
                path="facebook"
                lazy={() =>
                  getLazyComponent(
                    import("./components/session/login/LoginFacebookPage"),
                  )
                }
              />
            </Route>

            <Route
              path="xlsx-enrich"
              lazy={() =>
                getLazyComponent(
                  import("./components/session/EnrichRegPartDataButton"),
                )
              }
            />
          </Route>

          <Route
            exact
            path="/space/:id"
            lazy={() => getLazyComponent(import("./components/spaces/Space"))}
          />

          <Route
            exact
            path="/subscription"
            lazy={() =>
              getLazyComponent(
                import("./components/subscriptions/SubscriptionsPage"),
              )
            }
          />

          {/* Explicitly goes to the login page */}
          <Route exact path="/login" element={<LogInPage />} />
          <Route
            exact
            path="/signup"
            element={<LogInPage initState={"register"} />}
          />

          <Route
            exact
            path="/profile"
            lazy={() =>
              getLazyComponent(import("./components/profile/MyProfilePage"))
            }
          />
          <Route
            path="/profile/:id"
            lazy={() =>
              getLazyComponent(import("./components/profile/MyProfilePage"))
            }
          />

          <Route path="/organization">
            <Route
              index
              lazy={() =>
                getLazyComponent(
                  import("./components/organization/NoOrganizationPage"),
                )
              }
            />
            <Route
              path="create"
              lazy={() =>
                getLazyComponent(
                  import(
                    "./components/organization/create/CreateOrganizationPage"
                  ),
                )
              }
            />
            <Route
              path=":organizationId"
              lazy={() =>
                getLazyComponent(
                  import("./components/organization/OrganizationWrapper"),
                )
              }
            >
              <Route index element={<Navigate to="webinars" />} />
              <Route
                path="webinars"
                lazy={() =>
                  getLazyComponent(
                    import("./components/organization/webinars/WebinarsPage"),
                  )
                }
              />
              <Route path="members">
                <Route
                  path="settings"
                  lazy={() =>
                    getLazyComponent(
                      import(
                        "./components/organization/members/MembersSettings"
                      ),
                    )
                  }
                />
                <Route
                  path="invite"
                  lazy={() =>
                    getLazyComponent(
                      import(
                        "./components/organization/members/invite/InvitePage"
                      ),
                    )
                  }
                />
              </Route>
              <Route
                path="integrations"
                lazy={() =>
                  getLazyComponent(
                    import(
                      "./components/organization/integrations/IntegrationsPage"
                    ),
                  )
                }
              />

              <Route
                path="templates"
                lazy={() =>
                  getLazyComponent(
                    import(
                      "./components/organization/webinarTemplates/WebinarTemplates"
                    ),
                  )
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route
        exact
        path="/pdfviewer"
        lazy={() => getLazyComponent(import("./components/pdf/PdfViewer"))}
      />
      <Route
        exact
        path="/studiorecordendpoint"
        lazy={() =>
          getLazyComponent(
            import("./components/videocontainer/meeting/RecorderEndpoint"),
          )
        }
      />
      <Route
        path="/studiorecordendpoint/:id"
        lazy={() =>
          getLazyComponent(
            import("./components/videocontainer/meeting/RecorderEndpoint"),
          )
        }
      />

      {/* Remove eventually */}
      <Route path="/embed/:id" element={<VideoPlayerEmbedRedirect />} />
      <Route
        path="/videotester"
        lazy={() =>
          getLazyComponent(import("./components/videoplayer/VideoPlayer"))
        }
      />
      <Route
        path="/videoplayer"
        lazy={() =>
          getLazyComponent(import("./components/videoplayer/VideoPlayer"))
        }
      />
      {process.env.NODE_ENV === "development" && (
        <Route path="/dev">
          <Route
            exact
            path="video-test"
            lazy={() =>
              getLazyComponent(import("./components/videotest/VideoTest"))
            }
          />
          <Route
            path="simlivetest"
            lazy={() =>
              getLazyComponent(
                import(
                  "./components/videocontainer/videoplayers/simlive/SimLiveWrapper"
                ),
              )
            }
          />

          <Route
            path="univid-apps"
            lazy={() =>
              getLazyComponent(import("./components/DevUnividAppsPage.js"))
            }
          />
        </Route>
      )}

      <Route
        path="/salesforce/oauth2/callback"
        lazy={() =>
          getLazyComponent(
            import("./components/oauth-callback/SalesforceOAuthCallback"),
          )
        }
      />
      <Route
        exact
        path="/oauth-callback"
        lazy={() =>
          getLazyComponent(import("./components/oauth-callback/OAuthCallback"))
        }
      />

      <Route
        path="/bobby-tables/hunter2"
        lazy={() => getLazyComponent(import("./components/SuperadminPage"))}
      />

      {/* The default -> Just redirect to base url (TODO: Should probably rather give some kind of a 404 warning?) */}
      <Route path="*" element={<Navigate to="/" />} />
    </Route>,
  ),
);

root.render(
  <IntercomProvider appId={INTERCOM_APP_ID}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </IntercomProvider>,
);
