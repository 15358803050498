import React from "react";
import PropTypes from "prop-types";

import { reportError } from "../../logging/logger";
import styles from "./ErrorBoundary.module.css";
import ExpandableText from "../session/leftpanelmenu/ExpandableText";
import classNames from "classnames";
import * as Sentry from "@sentry/react";

const reloadPage = () => window.location.reload();

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null,
      errorComponentStack: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorMessage: error.message,
      errorComponentStack: errorInfo?.componentStack,
    });

    const messageAndComponentStack = `ErrorBoundary: ${error.message} ${errorInfo?.componentStack}`;
    const details = messageAndComponentStack
      .replaceAll("\n", "")
      .substring(0, 100);
    const detailedCaughtError = new Error(details, { cause: errorInfo });

    reportError(detailedCaughtError);
  }

  render() {
    const hasErrorInformation =
      this.state.errorMessage || this.state.errorComponentStack;

    const fallBack = (
      <div className={styles.ErrorBoundary}>
        <div className={styles.GeneralInfo}>
          <h2 className={styles.Heading}>Unfortunately something went wrong</h2>
          <p>
            We will look into it as soon as possible. While we fix the problem
            you can try reloading the page.
          </p>
          <button className={styles.Button} onClick={reloadPage}>
            Reload page
          </button>
          <p>
            Feel free to reach out to{" "}
            <a href="mailto:hello@univid.io">hello@univid.io</a> for assistance.
          </p>
        </div>
        {hasErrorInformation && (
          <div className={classNames(styles.Details, "no-translate")}>
            <ExpandableText maxHeight={25}>
              <h3 className={styles.Subheading}>Detailed error message</h3>
              {this.state.errorMessage && (
                <p className={styles.ErrorMessage}>{this.state.errorMessage}</p>
              )}
              {this.state.errorComponentStack && (
                <p className={styles.ComponentStack}>
                  {this.state.errorComponentStack}
                </p>
              )}
            </ExpandableText>
          </div>
        )}
      </div>
    );

    return (
      <Sentry.ErrorBoundary fallback={fallBack}>
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
