const languageObject = {
  english: {
    greeting: "Welcome!",
    upgradeGreeting: "Upgrade your account!",
    hintText: "Log in or create an account below.",
    upgradeHintText:
      "Upgrade to a full Univid account by completing the fields below.",
    emailInput: "Email",
    passwordInput: "Password",
    firstNameInput: "First Name",
    lastNameInput: "Last Name",
    logIn: "Log in",
    createAccount: "Create account",
    createNewAccount: "Create new account",
    or: "or",
    and: "and",
    forgotYourPassword: "Forgot your password?",
    error: "Error",
    iConsentToUnivids: "By proceeding, I consent to Univid's",
    privacyAgreement: "Privacy Agreement",
    termsOfUse: "Terms of Use",
    emailUpdates:
      "I want a free limited early bird access code to Univid and occasional updates via email, from Univid or the event organizer afterwards.",
    optional: "optional",
    register: "Register",
    upgrade: "Upgrade",
    backToLogin: "Go to log in",
    notYetVerified: "Your account is not yet verified",
    verificationEmailOnTheWay: "Luckily, a verification email is on the way to",
    spamFolder: "It might have ended up in the spam-folder",
    resend: "Resend",
    resetEmailOnTheWay: "A password reset email is on the way to",
    newPassword: "Save password",
    confirmNewPassword: "Confirm new password",
    yourPassWordHasBeenUpdated: "Your password has been updated!",
    requestNewPassword: "Request new password",
    continueWithoutLogin: "Continue without logging in",
    accountQuestions: "For questions regarding accounts, email",

    registerBeforeAttending:
      "The host of this session asks you to register before attending",
    alreadyHaveAccount: "Already have an account?",
    notAlreadyHaveAccount: "Don't have an account yet?",
  },

  swedish: {
    greeting: "Välkommen!",
    upgradeGreeting: "Uppgradera ditt konto!",
    hintText: "Logga in eller skapa ett konto nedan.",
    upgradeHintText:
      "Uppgradera till ett fullständigt Univid-konto genom att fylla i fälten nedan.",
    emailInput: "Email",
    passwordInput: "Lösenord",
    firstNameInput: "Förnamn",
    lastNameInput: "Efternamn",
    logIn: "Logga in",
    createNewAccount: "Skapa nytt konto",
    createAccount: "Skapa konto",
    or: "eller",
    and: "och",
    forgotYourPassword: "Glömt ditt lösenord?",
    error: "Fel",
    iConsentToUnivids: "Genom att fortsätta, godkänner jag Univids",
    privacyAgreement: "Privacy Agreement",
    termsOfUse: "Terms of Use",
    emailUpdates:
      "Jag vill ha en gratis medlemskod till Univid, samt uppdateringar via email.",
    optional: "valfritt",
    register: "Registrera",
    upgrade: "Uppgradera",
    backToLogin: "Till inloggning",
    notYetVerified: "Ditt konto är ännu inte verifierat",
    verificationEmailOnTheWay: "Ett verifieringsmail är på väg till",
    spamFolder: "Det kan ha hamnat i spam-korgen",
    resend: "Skicka igen",
    resetEmailOnTheWay: "Ett återställningsmail är på väg till",
    newPassword: "Spara lösenord",
    confirmNewPassword: "Bekräfta nytt lösenord",
    yourPassWordHasBeenUpdated: "Ditt lösenord har uppdaterats!",
    requestNewPassword: "Be om nytt lösenord",
    continueWithoutLogin: "Fortsätt utan att logga in",
    accountQuestions: "För frågor om konton, maila",

    registerBeforeAttending:
      "Värden för evenemanget ber dig att registrera dig innan du deltar",
    alreadyHaveAccount: "Har du redan ett konto?",
    notAlreadyHaveAccount: "Har du inte ett konto ännu?",
  },
};

export const textGenerator = (key, language) => {
  if (key == null) {
    return "";
  }

  const languageToUse =
    language != null && language in languageObject ? language : "english";

  if (key in languageObject[languageToUse]) {
    return languageObject[languageToUse][key];
  } else {
    return "";
  }
};
